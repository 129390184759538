<template>
  <div class="manage-project">
    <div class="container fluid">
      <project-table
        v-show="isLoaded"
        showDeveloper
        :canEdit="canEdit"
        :canAdd="canAdd"
        :canDelete="canDelete"
        :canViewReport="canViewReport"
        :canAllocateAgent="canAllocateAgent"
        :canAllocateBanker="canAllocateBanker"
        :canAllocateLawyer="canAllocateLawyer"
        :getProjectsAPI="projectAPI.getProjects"
        :getDevelopersAPI="developerAPI.getDevelopers"
        :deleteProjectAPI="projectAPI.deleteProject"
        :changeStatusAPI="projectAPI.updateStatus"
        @toAgentAllocation="toAgentAllocationPage"
        @toBankerAllocation="toBankerAllocation"
        @toLawyerAllocation="toLawyerAllocation"
        @toProjectReport="toProjectReport"
      ></project-table>
    </div>
  </div>
</template>

<script>
import ProjectTable from "@/modules/Project/components/ProjectTable";
import { developer } from "@/api";

import ProjectAPI from "@/modules/Project/api/project";

export default {
  name: "manage-project",
  components: {
    ProjectTable
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      canEdit: false,
      canAdd: false,
      canDelete: false,
      canAllocateAgent: false,
      canAllocateBanker: false,
      canAllocateLawyer: false,
      canViewReport: false,

      isLoaded: false,

      projectAPI: new ProjectAPI("agency"),
      developerAPI: developer
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.$store.commit("setIsLoading", true);
      await this.getAccessPermission();
      this.isLoaded = true;
      this.$store.commit("setIsLoading", false);
    },
    async getAccessPermission() {
      this.canAdd = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "project.create"
      );
      this.canEdit = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "project.update"
      );
      this.canDelete = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "project.delete"
      );
      this.canAllocateAgent = this.canViewReport = this.canAllocateBanker = this.canAllocateLawyer = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "projectPurchase.manage"
      );
    },
    toAgentAllocationPage(id) {
      this.$router.push({
        name: "ProjectAllocateAgents",
        params: { id: id }
      });
    },
    toBankerAllocation(id) {
      this.$router.push({
        name: "ProjectAllocateBankers",
        params: { id: id }
      });
    },
    toLawyerAllocation(id) {
      this.$router.push({
        name: "ProjectAllocateLawyers",
        params: { id: id }
      });
    },
    toProjectReport(id) {
      this.$router.push({
        name: "ProjectSalesReport",
        params: { id: id }
      });
    }
  }
};
</script>

<style lang="scss"></style>
