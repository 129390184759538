<template>
  <div class="manage-project-table">
    <div class="container fluid">
      <!-- ============================ Filter ============================ -->
      <filter-panel
        v-model="isFilterActive"
        :isLoading="isFilterLoading"
        :loadingItems="3"
        @clear="clearFilter"
        @filter="filterData"
      >
        <div class="row p-2">
          <fd-input
            v-model="filter['name[partial]']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Project Name"
            name="filterProjectName"
            type="text"
            @keyup.enter.native="filterData"
          >
          </fd-input>
          <fd-select
            v-if="showDeveloper"
            v-model="filter['developer:id']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Developer"
            :options="developerOptions"
          >
          </fd-select>
          <fd-select
            v-model="filter.status"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Status"
            :options="statusOptions"
          >
          </fd-select>
        </div>
      </filter-panel>
      <!-- ============================ Table ============================ -->
      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="projectTableColumns"
          :rows="projectTableData"
          :totalRows="projectTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false,
            initialSortBy: { field: 'updatedAt', type: 'desc' }
          }"
          @on-row-click="toProjectDetails"
        >
          <div slot="emptystate">
            <no-data message="There is no data"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button v-if="canAdd" class="btn main mr-2" @click="toAddProject">
              <i class="fas fa-plus"></i>
            </button>
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'" @click.stop>
              <dropdown-button buttonClass="circle flat">
                <template #buttonContent>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <template>
                  <div class="card text-left">
                    <fd-list-item
                      v-if="canEdit"
                      class="cursor-pointer unselectable"
                      @click="toEditProject(props.row.id)"
                    >
                      <i class="fas fa-pen"></i>
                      <span class="ml-2">Edit Project</span>
                    </fd-list-item>
                    <hr />
                    <fd-list-item
                      v-if="canAllocateAgent"
                      class="cursor-pointer unselectable"
                      @click="toAgentAllocationPage(props.row.id)"
                    >
                      <i class="fas fa-user-gear"></i>
                      <span class="ml-2">Allocate Agents</span>
                    </fd-list-item>
                    <fd-list-item
                      v-if="canAllocateBanker"
                      class="cursor-pointer unselectable"
                      @click="toBankerAllocation(props.row.id)"
                    >
                      <i class="fas fa-user-gear"></i>
                      <span class="ml-2">Allocate Bankers</span>
                    </fd-list-item>
                    <fd-list-item
                      v-if="canAllocateLawyer"
                      class="cursor-pointer unselectable"
                      @click="toLawyerAllocation(props.row.id)"
                    >
                      <i class="fas fa-user-gear"></i>
                      <span class="ml-2">Allocate Lawyers</span>
                    </fd-list-item>
                    <hr />
                    <fd-list-item
                      v-if="canEdit"
                      class="cursor-pointer unselectable"
                      @click="openChangeStatus(props.row)"
                    >
                      <i class="fas fa-exchange-alt"></i>
                      <span class="ml-2">Change Status</span>
                    </fd-list-item>
                    <fd-list-item
                      class="cursor-pointer unselectable"
                      @click="deleteProject(props.row.id)"
                    >
                      <i class="fas fa-trash"></i>
                      <span class="ml-2">Delete Project</span>
                    </fd-list-item>
                  </div>
                </template>
              </dropdown-button>
            </span>
            <span v-if="props.column.field == 'projectReport'" @click.stop>
              <fd-button
                class="bordered main"
                @click="toProjectReport(props.row.id)"
              >
                <i class="far fa-file-alt"></i>
              </fd-button>
            </span>
            <span v-if="props.column.field == 'projectStatus'">
              <project-status
                class="w-100"
                :status="props.row.status"
              ></project-status>
            </span>
            <span v-if="props.column.field == 'address'">
              <span v-if="props.row.building"
                >{{ $toTitleCase(props.row.building.name) }},</span
              >
              {{ $toTitleCase(props.row.area.name) }},
              {{ $toTitleCase(props.row.city.name) }},
              {{ $toTitleCase(props.row.state.name) }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
      <!-- ============================ Modals ============================= -->
      <modal v-model="changeProjectStatus.isShown">
        <change-status
          :project="changeProjectStatus.projectToChange"
          @submit="changeStatus"
          @cancel="changeProjectStatus.isShown = false"
        ></change-status>
      </modal>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { projectStatus } from "@/enums";

export default {
  name: "manage-project",
  components: {
    ProjectStatus: () => import("@/modules/Project/components/ProjectStatus"),
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    FdListItem: () => import("@/components/GlobalComponents/List/FdListItem"),
    ChangeStatus: () =>
      import("@/modules/Project/components/ChangeProjectStatus")
  },
  mixins: [vueGoodTable],
  props: {
    // Permission
    canAdd: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canAllocateAgent: {
      type: Boolean,
      default: false
    },
    canAllocateBanker: {
      type: Boolean,
      default: false
    },
    canAllocateLawyer: {
      type: Boolean,
      default: false
    },
    canViewReport: {
      type: Boolean,
      default: false
    },
    showDeveloper: {
      type: Boolean,
      default: false
    },
    // Routes
    addProjectRouteName: {
      type: String,
      default: "ManageProjectsAdd"
    },
    editProjectRouteName: {
      type: String,
      default: "ManageProjectsEdit"
    },
    projectDetailRouteName: {
      type: String,
      default: "ManageProjectsDetails"
    },
    // API
    getProjectsAPI: {
      type: Function,
      required: true
    },
    getDevelopersAPI: {
      type: Function
    },
    deleteProjectAPI: {
      type: Function
    },
    changeStatusAPI: {
      type: Function
    }
  },
  data: function () {
    return {
      projectTableData: [],
      projectTablePagination: {},
      projectTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false,
          hidden: false
        },
        {
          label: "Report",
          field: "projectReport"
        },
        {
          label: "Status",
          field: "projectStatus"
        },
        {
          label: "Project Name",
          field: "name"
        },
        {
          label: "Ref. Code Initial",
          field: "referenceCode"
        },
        {
          label: "Developer",
          field: "developer.name"
        },
        {
          label: "Address",
          field: "address"
        },
        {
          label: "Last Updated",
          field: "updatedAt",
          formatFn: (data) => {
            return this.$moment(data).format("lll");
          }
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      isFilterLoading: false,
      filter: {
        "name[partial]": "",
        "developer:id": "",
        status: ""
      },
      developerOptions: [],
      statusOptions: this.$mapJsonToArray(projectStatus, (e) => {
        return {
          id: e,
          name: e
        };
      }),

      changeProjectStatus: {
        isShown: false,
        projectToChange: {}
      }
    };
  },
  computed: {},
  watch: {
    showDeveloper: {
      immediate: true,
      handler(val) {
        let developerColumnIndex = this.projectTableColumns.findIndex(
          (column) => column.label === "Developer"
        );
        this.projectTableColumns[developerColumnIndex].hidden = !val;
      }
    },
    canViewReport: {
      immediate: true,
      handler(val) {
        let reportColumnIndex = this.projectTableColumns.findIndex(
          (column) => column.label === "Report"
        );
        this.projectTableColumns[reportColumnIndex].hidden = !val;
      }
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.$store.commit("setIsLoading", true);

      await Promise.all([this.getAllProjects()]);

      this.initAction();
      this.loadFilter();
      this.$store.commit("setIsLoading", false);
    },

    async loadFilter() {
      this.isFilterLoading = true;
      if (this.showDeveloper) {
        this.developerOptions = await this.getDevelopers();
      }
      this.isFilterLoading = false;
    },
    initAction() {
      let showAction = this.canEdit || this.canDelete;
      this.$set(this.projectTableColumns[0], "hidden", !showAction);
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllProjects();
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllProjects().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toAddProject() {
      this.$router.push({ name: this.addProjectRouteName });
    },
    toEditProject(id) {
      this.$router.push({
        name: this.editProjectRouteName,
        params: { id: id }
      });
    },
    toProjectDetails(param) {
      let id = param.row.id;
      this.$router.push({
        name: this.projectDetailRouteName,
        params: { id: id }
      });
    },
    toProjectReport(id) {
      this.$emit("toProjectReport", id);
    },
    toAgentAllocationPage(id) {
      this.$emit("toAgentAllocation", id);
    },
    toBankerAllocation(id) {
      this.$emit("toBankerAllocation", id);
    },
    toLawyerAllocation(id) {
      this.$emit("toLawyerAllocation", id);
    },
    openChangeStatus(project) {
      this.changeProjectStatus.isShown = true;
      this.changeProjectStatus.projectToChange = project;
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllProjects();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllProjects();
    },
    // ========================== API Related =========================
    async getAllProjects() {
      this.$store.commit("setIsLoading", true);
      try {
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await this.getProjectsAPI({
          queries: filteredParam,
          page: this.tableParams.page,
          perPage: this.tableParams.perPage
        });

        this.projectTableData = this._.cloneDeep(data.data);
        this.projectTablePagination = this._.cloneDeep(data.meta.pagination);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    },
    async deleteProject(id) {
      let confirmDelete = await this.$confirm({
        type: "alert",
        message: "Are you sure you want to delete this project?"
      });

      if (confirmDelete) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.deleteProjectAPI(id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Project is deleted successfully."
          });
          await this.getAllProjects();
          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "An unexpected error occured. Please try again later."
          });
          this.$reportError(error, "Delete Project");
          throw error;
        }
      }
    },
    async getDevelopers() {
      try {
        let developers = await this.getDevelopersAPI({
          page: 1,
          perPage: 100
        });
        return developers.data;
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text:
            "Error loading developer options for filter, please try again later."
        });
        throw error;
      }
    },
    async changeStatus(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.changeStatusAPI({
          id: id,
          payload: payload
        });
        await this.getAllProjects();
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Status changed successfully."
        });
        this.changeProjectStatus.isShown = false;
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text:
            "Error loading developer options for filter, please try again later."
        });
        this.$reportError(error, "Change Project Status");
        throw error;
      }
    }
  }
};
</script>

<style lang="scss"></style>
